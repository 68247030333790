import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";


Alpine.data('newsletter_index', () => ({
    currentIndex: 1,
    lastScrollY: 0,
    scrollingUp: false,
    progress: 0,
    init() {
        this.updateText();
    },
    checkScroll() {
        const currentScrollY = window.scrollY;
        this.scrollingUp = currentScrollY < this.lastScrollY;
        this.lastScrollY = currentScrollY;


    },
    updateIndex(index) {
        this.currentIndex = index;
        this.updateText();
    },
    updateText() {
        const element = document.getElementById('articleNumber');
        if (element) {
            element.innerText = this.currentIndex;
        }
    }

}))