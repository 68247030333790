import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('consent', {});

Alpine.data('cookieNotice', (config) => ({
    tab: 'overview',
    init() {
        const consent = window.CookieNotice.cookieExists(config.cookie_name) ? null : false;
        config.consent_groups.forEach(group => this.$store.consent[group.handle] = consent);
        window.CookieNotice.on('accepted', (group) => this.$store.consent[group] = true);
        window.CookieNotice.on('declined', (group) => this.$store.consent[group] = false);
        window.CookieNotice.boot(this.$root, config);
        this.$dispatch('cookie-notice-load');
    },
    show() {
        window.CookieNotice.showWidget();
    },
    hide() {
        window.CookieNotice.hideWidget();
    },
    acceptAll() {
        window.CookieNotice.config.consent_groups.forEach((consentGroup) => {
            window.CookieNotice.widget.querySelector(`[name='group-${consentGroup.handle}']`).checked = true;
        });
        window.CookieNotice.savePreferences();
        this.$dispatch('cookie-decided', { decision: 'accepted-all' });

    },
    acceptSelected() {
        this.$dispatch('cookie-decided', { decision: 'accepted-all' });

    }
}));

Alpine.data('consentGate', ({ category }) => ({
    consent: null,
    init() {
        Alpine.effect(() => {
            this.consent = this.$store.consent[category];
            this.$nextTick(() => {
                if (this.consent === true) {
                    this.executeScripts(this.$root.innerHTML);
                }
            });
        });
    },
    executeScripts(html) {
        document.createRange()
            .createContextualFragment(html)
            .querySelectorAll('script')
            .forEach(script => {
                document.body.appendChild(script.cloneNode(true));
            });
    },
}));