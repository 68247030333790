import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('beyondwords', ({ projectID, sourceID, updateEventId }) => ({
  projectID: parseInt(projectID, 10),
  sourceID: sourceID,
  updateEventId: updateEventId,
  state: 'not-ready',
  player: null,
  init() {
    if (window.BeyondWords) {
      this.initPlayer();
    } else {
      window.addEventListener('BeyondWordsReady', () => {
        // console.log('BeyondWordsReady');
        this.initPlayer();
      });
    }
  },
  initPlayer() {
    this.player = new BeyondWords.Player({
      projectId: this.projectID,
      sourceId: this.sourceID,
      clientSideEnabled: true,
      highlightSections: 'none',
    });
    // this.player.addEventListener('NoContentAvailable', () => {
    //console.log('BeyondWords “NoContentAvailable” for', this.sourceID);
    // });
    this.player.addEventListener('ContentAvailable', () => {
      //console.log('BeyondWords “ContentAvailable” for', this.sourceID);
      this.state = 'not-started';
      this.player.addEventListener('<any>', this.update.bind(this));
    });
  },
  play() {
    this.state = 'playing';
    this.player.playbackState = this.state;
  },
  pause() {
    this.state = 'paused';
    this.player.playbackState = this.state;
  },
  toggle() {
    if (this.state == 'playing') {
      this.state = 'paused';
    } else {
      this.state = 'playing';
    }
    this.player.playbackState = this.state;
  },

  update() {
    if (this.player.playbackState == 'playing' || this.player.playbackState == 'paused') {
      if (this.player.currentTime && this.player.duration) {
        const progress = this.player.currentTime / this.player.duration;
        this.$dispatch(this.updateEventId, { progress });
      }
    } else {
      this.$dispatch(this.updateEventId, { progress: 0 });
    }
    this.state = this.player.playbackState;
  }

}));
