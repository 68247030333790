import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("video_player", ({
  uid,
  jwpMediaId,
  motionThumb,
  mp4,
  aspectRatio,
  autoPlay,
  playOnIntersect,
  preload,
  mute,
  chromeless,
  loop,
  pauseWhenOthersPlay,
  pauseWhenNotVisible,
  listenForOffset,
  nextEpisode
}) => ({
  uid: uid,
  jwpMediaId: jwpMediaId,
  motionThumb: motionThumb,
  mp4: mp4,
  aspectRatio: aspectRatio,
  autoPlay: autoPlay,
  playOnIntersect: playOnIntersect,
  preload: preload,
  mute: mute,
  chromeless: chromeless,
  loop: loop,
  pauseWhenOthersPlay: pauseWhenOthersPlay,
  pauseWhenNotVisible: pauseWhenNotVisible,
  listenForOffset: listenForOffset,
  nextEpisode: nextEpisode,
  API_URL: `https://cdn.jwplayer.com/v2/media/${jwpMediaId}?format=json`,
  player: null,
  source: null,
  remainBeforeSkip: 99,
  showNextEpButton: false,
  init() {
    if (this.mp4) {
      this.player = this.wrapHtmlPlayer();
    } else {
      this.player = jwplayer(this.$refs.video).setup(this.jwOptions());
    }
    this.registerPlayer();
    this.attachEvents();
    if (this.listenForOffset) {
      const params = new URL(location.href).searchParams;
      let offset = params.get('t');
      if (offset) {
        offset = parseInt(offset, 10);
        if (Number.isInteger(offset)) {
          this.player.seek(offset);
        }
      }

    }
  },
  registerPlayer() {
    // The reels script (js/alpine/ReelsPlayer) defines a callback
    // function stored under the UID in window.frop_players to be
    // run here once the JWPlayer has initialised.
    if (window.frop_players === undefined) window.frop_players = {};
    if (window.frop_players_pause === undefined) window.frop_players_pause = {};
    const cb = window.frop_players[this.uid];
    window.frop_players[this.uid] = this.player;
    if (typeof cb === "function") cb();
    if (this.pauseWhenOthersPlay) window.frop_players_pause[uid] = this.player;
  },
  wrapHtmlPlayer() {
    const p = {
      eventQueues: {}
    };
    p.on = (eventName, callBack) => {
      if (typeof p.eventQueues[eventName] === 'undefined') {
        p.eventQueues[eventName] = [];
      }
      p.eventQueues[eventName].push(callBack);
    }
    p.trigger = (eventName, data) => {
      if (typeof p.eventQueues[eventName] === 'undefined') {
        return;
      }
      p.eventQueues[eventName].forEach(e => {
        e(data);
      });
    }
    p.play = () => {
      p.trigger('play');
      this.$refs.html_video.play();
    }
    p.pause = () => {
      this.$refs.html_video.pause();
    }
    p.getMute = () => {
      return this.$refs.html_video.muted;
    }
    p.setMute = (mute) => {
      if (typeof mute !== "boolean") {
        throw new Error('setMute accepts only boolean values');
      }
      p.trigger('mute', { 'mute': mute });
      this.$refs.html_video.muted = mute;
    }
    p.setVolume = (volume) => {
      if (typeof volume !== 'number') {
        throw new Error('setVolume accepts only integers');
      }
      if (volume < 0 || volume > 100) {
        throw new Error('setVolume accepts only integers in the range 0-100 inclusive');
      }
      volume = volume / 100;
      this.$refs.html_video.volume = volume;
    }
    p.seek = (time) => {
      this.$refs.html_video.currentTime = time;
    }
    return p;
  },
  jwOptions() {
    return {
      playlist: `https://cdn.jwplayer.com/v2/media/${this.jwpMediaId}`,
      width: '100%',
      height: '100%',
      aspectratio: `${this.aspectRatio}`,
      autostart: this.autoPlay,
      mute: this.mute,
      preload: this.preload,
      controls: !this.chromeless,
      repeat: this.loop,
      autoPause: { viewability: this.pauseWhenNotVisible },
      displaytitle: false,
      displayHeading: false,
      displaydescription: false,
    };
  },
  aspectRatioFloat() {
    return this.aspectRatio.split('/').map(e => Number.parseFloat(e)).reduce((acc, e) => e / acc, 1);
  },
  async loadMP4() {
    if (this.source) return;
    const ar = this.aspectRatioFloat();
    const h = this.$refs.video.offsetWidth * ar;
    const response = await fetch(this.API_URL);
    const json = await response.json();
    if (response.status === 200) {
      if (this.motionThumb) {
        const imageThumb = json.playlist[0].images.find(o => (o.width === 640)).src;
        this.source = imageThumb.replace('.jpg', '.mp4');
      } else {
        let availableMP4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
        let i = 0;
        while (!this.source && i < availableMP4Sources.length) {
          let candidate = availableMP4Sources[i];
          if (candidate.height > h) {
            this.source = candidate.file;
          }
          i++;
        }
      }
    }
  },
  attachEvents() {
    this.player.on("play", this.play.bind(this));
    if (this.nextEpisode) {
      this.player.on("time", this.checkProgress.bind(this));
    }
  },
  checkProgress(evt) {
    const remains = evt.duration - evt.currentTime;
    this.remainBeforeSkip = (15 - Math.min(remains, 15)) / 15 * 100;
    this.showNextEpButton = this.remainBeforeSkip > 0;
    if (remains < 0.1) window.location = `${window.location.origin}${this.nextEpisode}`;
  },
  play(evt) {
    Object.keys(window.frop_players_pause).forEach((playerId) => {
      let player = window.frop_players_pause[playerId];
      if (playerId !== this.uid && player.getState() == "playing") {
        player.pause();
      }
    });
  },
  intersectStart() {
    if (this.playOnIntersect) {
      this.player.play();
    }
  },
  intersectStop() {
    if (this.pauseWhenNotVisible) {
      this.player.pause();
    }
  }
}));
