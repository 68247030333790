import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('chartbeat', (url, headline, sections) => ({
  url: url,
  headline: headline,
  sections: sections,
  ready: false,
  queue: [],
  init() {
    //console.log('hey');
    if (window.pSUPERFLY) {
      //console.log('pSUPERFLY was already here');
      this.processQueue();
      this.ready = true;
    } else {
      let i = setInterval(() => {
        if (window.pSUPERFLY) {
          //console.log('waited for pSUPERFLY, ready now');
          this.processQueue();
          this.ready = true;
          clearInterval(i);
          i = null;
        }
      }, 1000);
    }
  },
  track() {
    //console.log('track method called');
    this.queue.push({
      sections: this.sections,
      authors: "",
      path: this.url,
      title: this.headline
    });
    if (this.ready) {
      //console.log('track method called, was ready, calling processQueue');
      this.processQueue();
    }
  },
  processQueue() {
    //console.log('processQueue');
    while (this.queue.length > 0) {
      //console.log('processQueue loop');
      pSUPERFLY.virtualPage(this.queue.shift());
    }
  }
}));