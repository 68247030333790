import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";


Alpine.data('newsletter_title', () => ({
    isSticky: false,
    lastScrollY: 0,
    translateY: 0,
    topMenuHeight: 0,
    index: 1,
    init() {
        this.topMenuHeight = document.getElementById('top_logo').offsetHeight;
    },
    checkScroll() {
        const currentScrollY = window.scrollY;
        if (currentScrollY > 0) {
            if (currentScrollY < this.lastScrollY) {
                this.translateY = this.topMenuHeight;
            } else {
                this.translateY = 0;
            }
        } else {
            this.translateY = 0;
        }
        this.lastScrollY = currentScrollY;
    },
    updateIndex(newIndex) {
        this.index = parseInt(newIndex, 10);
    }
}))